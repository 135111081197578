<template>
  <a-card class="not-available-options-overlay relative">
    <div class="not-available-options-overlay__content flex flex-col items-center justify-center h-full">
      <!-- DESCRIPTION -->
      <div
        v-if="description || tags"
        class="flex flex-row"
      >
        <div
          v-if="description"
          v-html="description"
          class="text-center relative"
        />
        <tags
          class="absolute"
          style="right: 10px; top: 5px"
          v-if="tags"
          :tags="tags"
        />
      </div>

      <div
        v-if="withButton"
        :class="{'mt-5': description || tags}"
      >
        <!-- BUY BUTTON -->
        <a-button
          class="w-full btn-wrap"
          type="danger"
          size="large"
          icon="shopping"
          @click="$router.push(buyLink)"
        >
          {{ $t("button_license_buy") }}
        </a-button>
      </div>

      <div
        v-if="video"
        class="mt-4"
      >
        <video
          ref="videoPlayer"
          class="video-js"
        />
      </div>
    </div>
  </a-card>
</template>

<script>
import Tags from "piramis-base-components/src/components/Tags/Tags.vue";
import { EntityTypes } from "piramis-base-components/src/components/SelectEntityWizard/includes/types";

import videojs from 'video.js';
import 'video.js/dist/video-js.min.css'

export default {
  name : "NotAvailableOptionsOverlay",
  props: {
    description: {
      type: String,
    },
    withButton : {
      type: Boolean,
      default: true
    },
    tags: Array,
    video: {
      type: String
    }
  },

  components: {
    Tags,
  },

  computed: {
    buyLink() {
      if (this.$store.state.chatState.chat?.chat_id) {
        return {
          name  : "buy",
          params: {
            [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id,
          },
          player: null
        }
      } else {
        return {
          name: "chats",
        }
      }
    }
  },
  mounted() {
    if (this.video) {
      this.player = videojs(this.$refs.videoPlayer, {
        autoplay: true,
        loop: true,
        controls: true,
        sources: [
          {
            src: this.video
          }
        ],
      }, function onPlayerReady() {
        this.el_.style.width = '100%'
        this.el_.style.height = 'auto'
        this.el_.style.maxHeight = '50%'
      })
    }
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
    }
  }
};
</script>

<style lang="scss">
	.not-available-options-overlay {
		z-index: 10;
		backdrop-filter: blur(10px);
		background-color: rgba(255, 255, 255, 1);

    &__tag {
      position: absolute;
      top: 10px;
      right: 0;
    }

		&__content {

		}

    .video-js {
      overflow: hidden;
    }

    .video-js,
    .vjs_video_3-dimensions {
      max-width: 100% !important;
      max-height: 100% !important;
      border-radius: 8px !important;
    }

    .video-js .vjs-tech {
      position: relative !important;
    }
	}
</style>

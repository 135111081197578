










































































import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import { Board, Target } from '@/views/posts-planner/posts.types'
import { BRAND_TAG, DEFAULT_BOARD_TOKEN } from '@/includes/constants'

import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import AvatarsRow from 'piramis-base-components/src/components/AvatarsRow.vue'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";

import Vue from 'vue'
import Component from 'vue-class-component'

@Component({
  components: {
    PageTitle,
    Tags,
    NotAvailableOptionsOverlay,
    CenteredColumnLayout,
    AvatarsRow
  },
  data() {
    return {
      DEFAULT_BOARD_TOKEN,
      BRAND_TAG
    }
  }
})
export default class Boards extends Vue {
  get boards(): Array<Board> {
    return this.$store.state.postsPlanner?.boards || []
  }

  boardRoute(routeName: string, board: Board): void {
    this.$router.push({
      name: routeName,
      params: {
        [EntityTypes.BOT_ID]: board.board.token_id.toString()
      },
    })
  }

  firstSevenTargets(targets: Array<Target>): Array<SelectOptionData> {
    return targets
      .slice(0, 7)
      .sort((a: Target, b: Target) => b.weight - a.weight)
      .map(t => {
        return {
          label: t.title,
          value: t.id,
          image: {
            src: t.photo,
          }
        }
      })
  }
}
